import s from 'styled-components'
import IconWallet from '../../images/tmn_banner-3.png'
import IconSeven from '../../images/7app_banner-3.png'
import WallpaperMain from '../../images/wallpaper-3.jpg'
import Banner from '../../images/[711]-Coin-campaign-2020.jpg'

import React from 'react'

// const Box = s.div`
//     padding-top:40px;
//     display: -ms-grid;
//     display: grid;
//     -ms-grid-columns: 1fr 20px 1fr;
//     grid-template-columns: 1fr 1fr;
//     justify-items: stretch;
//     -webkit-box-align: stretch;
//     -ms-flex-align: stretch;
//     align-items: stretch;
//     & > div {
//         text-align:center;
//         border : 1px solid #000;
//     }
// `

const Warpper = s.div`
    padding-top: 40px;
    margin: 0 auto;
    max-width: 380px;
    position: relative;
    height: 100vh;
`

const Image2 = s.img`
    width: 100%;
    cursor: pointer;
`

const BoxNewImage = s.div`
  padding: 10px;
  text-align: center;
`

const Box2 = s.div`
  height: calc(100% - 40px);
`

const ImgMain = s.img`
  width: 100%;
  position: absolute;
  left: 0;
  top:0;
`

const BoxMain = s.div`
  position: absolute;
  top:0;
  left:0;
`

const ViewMenu = props => (
  <Warpper>
    <ImgMain src={WallpaperMain} alt=""/>
    <BoxMain>
     <img src={Banner} alt="" style={{ width: '100%' }} />
      <Box2>
        <BoxNewImage onClick={props.onClickApp1}>
          <Image2 src={IconSeven} alt=""/>
        </BoxNewImage>
        <BoxNewImage onClick={props.onClickApp2}>
          <Image2 src={IconWallet} alt=""/>
        </BoxNewImage>
      </Box2>
    </BoxMain>
  </Warpper>
)

export default ViewMenu
