import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'
import View from '../components/presentation/menu'
import homeActions from '../state/home/actions'
import queryString from 'query-string'

class MenuPage extends React.Component {

  state = {
    isLoading: true,
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)
    setTimeout(() => {
      this.setState({ isLoading: false }, () => {
        this.props.onPhoneSaveToTemp({
          staff_id: values.id,
          phone_number: values.phone,
          app_id: '1',
        })
      })
    }, 1000)
  }


  onClickApp1 = () => {
    this.props.state.phone_temp.app_id = '22'
    this.props.onPhoneSubmit(this.props.state.phone_temp, 'https://www.7eleven.mobi/navPage/SE000')
  }

  onClickApp2 = () => {
    this.props.state.phone_temp.app_id = '21'
    this.props.onPhoneSubmit(this.props.state.phone_temp, 'https://tmn.app.link/grmmXf3TM1')
  }

  render() {
    if (this.state.isLoading) {
      return <Loading/>
    }
    return (
      <Layout type={2}>
        <SEO keywords={[`Staffqr`]}/>
        <View
          onClickApp1={this.onClickApp1}
          onClickApp2={this.onClickApp2}/>
      </Layout>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    state: state.home,
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    onPhoneSubmit: homeActions.onPhoneSubmit,
    onPhoneSaveToTemp: homeActions.onPhoneSaveToTemp,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage)
